import React from "react";
import Layout from "../../layouts";

import './style.css'

const PrivacyPolicyEn = () => {
    return (
        <Layout lang="de" showNav={false}>
            <h1>Wave Budget – Datenschutzbestimmungen</h1>
            <p>
                Diese App wurde von Stefan Galler entwickelt und soll den User dabei unterstützen seine finanziellen Ausgaben zu
                überwachen.
            </p>

            <h2>Datenerhebung</h2>
            <p>
                Die Daten, die in diese App eingegeben werden, werden mithilfe von Firebase Firestore (siehe auch "Verwendete
                Drittanbieter") in der "Cloud" gespeichert.
            </p>

            <h2>Log Dateien</h2>
            <p>
                Im Falle eines App-Fehlers werden Daten (z.B.: Betriebssystemversion, Gerätekonfiguration) über den Ursprung des
                Fehlers gespeichert (“Log Dateien”). Um den Fehler beheben zu können, werden diese Daten durch einen Drittanbieter
                ("Firebase Crashlytics") bearbeitet.
            </p>

            <h2>Statistiken</h2>
            <p>
                Um die Qualität der App zu verbessern, werden Statistiken über das Nutzungsverhalten erhoben. Diese Statistiken
                werden für 2 Monate gespeichert. Zusammengefasste Statistiken (Summen, Durchschnitte, ...) werden länger
                gespeichert.
            </p>

            <h2>Verwendete Drittanbieter</h2>
            <p>Folgende Drittanbieter werden von unserer App verwendet:</p>

            <h3>Firebase Authentifizierung</h3>
            <p>
                Mit diesem Dienst wird die Authentifizierung der App umgesetzt. Dazu werden folgende personenbezogene Daten
                verarbeitet:
            </p>
            <ul>
                <li>Passwörter</li>
                <li>E-Mail Adressen</li>
                <li>Telefonnummern</li>
                <li>Benutzeragenten</li>
                <li>IP-Adressen</li>
            </ul>
            <p>Firebase - Authentifizierung hält IP - Adressen für ein paar Wochen angemeldet. Es bewahrt andere
                Authentifizierungsinformationen auf, bis der Firebase-Kunde die Löschung des zugehörigen Benutzers veranlasst.
                Danach werden die Daten innerhalb von 180 Tagen aus Live- und Backup-Systemen entfernt.</p>


            <h3>Firebase Crashlytics</h3>
            <p>Mit diesem Dienst werden Statistiken und Ursachen von Abstürzen und Fehlern protokolliert. Dabei werden folgende
                personenbezogene Daten verarbeitet:</p>
            <ul>
                <li>Crashlytics-Installations-UUIDs</li>
                <li>Absturzspuren</li>
                <li>Breakpad-Minidump-formatierte Daten</li>
            </ul>
            <p>Firebase Crashlytics behält crash Stapelüberwachungen, minidump Daten und entsprechenden Kennungen (einschließlich
                Crashlytics Installations UUID) für 90 Tage.</p>

            <h3>Google Analytics für Firebase</h3>
            <p>Mit diesem Dienst werden Nutzungsdaten der App protokolliert. Dazu werden folgende personenbezogene Daten
                verarbeitet:</p>
            <ul>
                <li>Firebase-Installations-IDs</li>
                <li>Analytics-App-Instanz-IDs</li>
            </ul>
            <p>Ereignisdaten werden für 2 Monate gespeichert.</p>

            <h3>Firestore</h3>
            <p>Mit diesem Dienst werden die Daten die in die App eingegeben werden in der Cloud gespeichert.</p>
            <p>Diese Daten werden so lange gespeichert, bis der User seinen Account löscht / löschen lässt.</p>

            <h2>Ihre Rechte</h2>
            <p>
                Ihnen stehen bezüglich Ihrer von uns verarbeiteten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung,
                Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung
                Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise
                verletzt worden sind, können Sie sich bei mir (siehe Kontakt) oder der Datenschutzbehörde beschweren.
            </p>

            <h2>Kontakt</h2>
            <p>
                Bei Fragen wenden Sie sich gerne an galler.stefan@gmail.com.
            </p>
        </Layout>
    )
}

export default PrivacyPolicyEn